import { Link, navigate } from 'gatsby';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import useResume from '../../hooks/useResume';
import LoadingScreen from '../../components/router/LoadingScreen';
import Pikachu from '../../components/builder/templates/Pikachu';
import ZetyBanking from '../../components/builder/templates/ZetyBanking';
import ZetyManagement from '../../components/builder/templates/ZetyManagement';
import styles from './view.module.css';
import WallStreet from '../../components/builder/templates/WallStreet';
import ZetyDataAnalyst from '../../components/builder/templates/ZetyDataAnalyst';
import useUser from '../../hooks/useUser';

import ROOT_URL from '../../constants/url';

const ResumeViewer = ({ id }) => {
  const { _t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { resume, getLatestResume, getResumeSharing } = useResume();
  const { user } = useUser();

  useEffect(() => {
    (async () => {
      const data = await getLatestResume(id);
      const isPublic = await getResumeSharing(id);

      if (!data) {
        navigate('/');
        toast.error(
          `The resume you were looking for does not exist anymore... or maybe it never did?`,
        );
        return null;
      }

      if (!isPublic && (user == null || data.user !== user.uid)) {
        navigate('/');
        toast.error(`You do not have the permissions to view this resume`);
        return null;
      }

      i18n.changeLanguage(data.metadata.language || 'en');
      return setLoading(false);
    })();
  }, [id]);

  return useMemo(() => {
    if (loading) {
      return <LoadingScreen />;
    }

    return (
      <div className={styles.container}>
        <Helmet>
          <title>{resume.name} | CareerSocius Resume Builder</title>
          <link rel="canonical" href={`${ROOT_URL}/r/${id}`} />
          {resume.metadata.template === 'zetymanagement' ||
          resume.metadata.template === 'zetydataanalyst' ? (
            <style>{`@page {margin:5mm;}`}</style>
          ) : (
            <style>{`@page {margin:${resume.metadata.margins.tbMargins}mm 0;}`}</style>
          )}
        </Helmet>

        <div
          className={styles.page}
          style={{
            backgroundColor:
              resume.metadata.template !== 'zetymanagement'
                ? resume.metadata.colors.background
                : 'white',
            position: 'relative',
            paddingTop:
              resume.metadata.template === 'zetymanagement' ||
              resume.metadata.template === 'zetydataanalyst'
                ? '0'
                : resume.metadata.margins.tbMargins,
            paddingBottom:
              resume.metadata.template === 'zetymanagement' ||
              resume.metadata.template === 'zetydataanalyst'
                ? '0'
                : resume.metadata.margins.tbMargins,
          }}
        >
          {resume.metadata.template === 'pikachu' && <Pikachu data={resume} />}

          {resume.metadata.template === 'zetybanking' && (
            <ZetyBanking data={resume} />
          )}
          {resume.metadata.template === 'wallStreet' && (
            <WallStreet data={resume} />
          )}
          {resume.metadata.template === 'zetymanagement' && (
            <ZetyManagement data={resume} />
          )}
          {resume.metadata.template === 'zetydataanalyst' && (
            <ZetyDataAnalyst data={resume} />
          )}
        </div>
        <p className={styles.footer}>
          Built with <Link to="/">CareerSocius Resume Builder</Link>
        </p>
      </div>
    );
  });
};

export default memo(ResumeViewer);
