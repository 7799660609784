import { Redirect, Router } from '@reach/router';
import React, { memo } from 'react';
import Wrapper from '../components/shared/Wrapper';
import ResumeViewer from './r/view';
import NotFound from './404';

const ResumeRouter = () => (
  <Wrapper>
    <Router basepath="/r">
      <Redirect noThrow from="/" to="/" exact default />
      <ResumeViewer path="/:id" />
      <NotFound default />
    </Router>
  </Wrapper>
);

export default memo(ResumeRouter);
